import { Disclosure } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import DropdownButton from "../NavbarComponents/DropdownButton";
import CollapseButton from "../NavbarComponents/CollapseButton";
import logoSIBUCSC from "../../assets/images/home/navbar/SIBUCSC.png";

const navigation = [
  { name: "Inicio", href: "/", current: false },
  {
    name: "Biblioteca Digital",
    path: "digital",
    href: "/digital",
    current: false,
  },
  {
    name: "Apoyo a la Investigación",
    current: false,
    subList: [
      { name: "Herramientas de Apoyo", href: "/inv/apoyo" },
      { name: "Herramientas de Publicación", href: "/inv/publicacion" },
    ],
  },

  {
    name: "Bibliotecas",
    current: false,
    subList: [
      { name: "Quiénes somos", href: "/direccion" },
      { name: "San Andrés", href: "/biblioteca/BSA" },
      { name: "Santo Domingo", href: "/biblioteca/BSD" },
      { name: "Campus Clínico", href: "/biblioteca/BCH" },
      { name: "Sede Talcahuano", href: "/biblioteca/ITTA" },
      { name: "Sede Chillán", href: "/biblioteca/ITCH" },
      { name: "Sede Cañete", href: "/biblioteca/ITCA" },
      { name: "Sede Los Ángeles", href: "/biblioteca/ITLA" },
    ],
  },
  {
    name: "Solicitudes",
    href: "#",
    current: false,
    subList: [
      { name: "Solicitud de Talleres", href: "/stalleres" },
      { name: "Solicitud Artículos", href: "/sarticulos" },
    ],
  },
  {
    name: "Ayuda",
    href: "#",
    current: false,
    subList: [
      { name: "Preguntas Frecuentes", href: "/ayuda" },
      { name: "Tutoriales de ayuda", href: "/ayuda-digital" },
      { name: "Consulta a tu bibliotecario", href: "/consultas" },
    ],
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Navbar = () => {
  const path = window.location.pathname.split("/");
  if (path[1] === "") {
    navigation[0].current = true;
  } else {
    // eslint-disable-next-line array-callback-return
    navigation.map((e) => {
      if (e.path === path[1]) {
        e.current = true;
      }
    });
  }

  return (
    <Disclosure as="nav" className="barnav sticky sm:flex top-0">
      {({ open }) => (
        <>
          <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
            <div className="relative flex items-center justify-between h-16">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                {/* Mobile menu button*/}

                <Disclosure.Button className="inline-flex text-black focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="sr-only">Abrir menú</span>

                  {open ? (
                    <XIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex-shrink-0 flex items-center">
                <img
                  className="hidden lg:block"
                  style={{ height: "80px", width: "auto" }}
                  src={logoSIBUCSC}
                />
              </div>

              <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                <div className="hidden sm:block sm:ml-6">
                  <div className="flex space-x-3">
                    {navigation.map((item) =>
                      item.subList ? (
                        <DropdownButton item={item} key={item.name} />
                      ) : (
                        <a
                          key={item.name}
                          href={item.href}
                          className={classNames(
                            item.current ? "bg-[#c9CCD2]" : "hover:text-white",

                            "navMenu cursor-pointer !hover:bg-white-700 !hover:text-white px-3 py-2 rounded-md !text-sm !font-bold d-flex"
                          )}
                          aria-current={item.current ? "page" : undefined}
                        >
                          {item.name}
                        </a>
                      )
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* MENU PARA DISPOSITIVO MOVIL SIN SUBMENÚ */}
          <Disclosure.Panel className="sm:hidden">
            <div className="px-2 pt-2 pb-3 space-y-1">
              {navigation.map((item) =>
                item.subList ? (
                  <CollapseButton item={item} key={item.name}></CollapseButton>
                ) : (
                  <Disclosure.Button
                    key={item.name}
                    as="a"
                    href={item.href}
                    className={classNames(
                      item.current
                        ? "bg-white-900 text-[#d12421]"
                        : "text-[#646567] hover:bg-black-700 hover:text-white",

                      "block px-3 py-2 rounded-md text-base font-medium"
                    )}
                    aria-current={item.current ? "page" : undefined}
                  >
                    {item.name}
                  </Disclosure.Button>
                )
              )}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};
export default Navbar;
