import React, { useState, useRef, useEffect } from "react";
import { Container, Tab, Row, Col, Nav, Button, Form } from "react-bootstrap";
import Swal from "sweetalert2";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import { FaSearch, FaTrash } from "react-icons/fa";
import "../../css/Request.css";
import Banner from "../../assets/images/banner/banner_request_art.png";
import ChileanRutify from "chilean-rutify";
import moment from "moment";
import { parseBibFile } from "bibtex";

const RightAlignedNavs = () => {
  const [activeTab, setActiveTab] = useState("first"); // Estado para la pestaña activa
  const [inputs, setInputs] = useState([""]); // Inicializamos con un único input
  const [results, setResults] = useState(null); // Estado para guardar los resultados de la API
  const [inputStatus, setInputStatus] = useState(["default"]); // Estados: "default", "success", "error"
  const [currentDOI, setCurrentDOI] = useState(""); // Nuevo estado para el DOI actual
  const [hasQueried, setHasQueried] = useState(false); // Controla si se muestra el mensaje predeterminado
  const fecha_sol = moment().format("YYYY-MM-DD");
  const recaptchaForm1 = useRef();
  const recaptchaForm2 = useRef();
  const [contactData, setContactData] = useState({
    rut: "",
    email: "",
    tipo_usuario: "",
    comentarios: "",
    fecha_sol,
  });
  const [showRecaptcha, setShowRecaptcha] = useState(false); // Estado para controlar la renderización del reCAPTCHA

  // Manejar el cambio de pestaña
  const handleTabSelect = (key) => {
    setActiveTab(key);
  };

  // Agregar un nuevo input
  const handleAddInput = () => {
    if (inputs.length < 5) {
      setInputs([...inputs, ""]);
    } else {
      Swal.fire(
        "Máximo alcanzado",
        "Solo puedes agregar hasta 5 inputs.",
        "info"
      );
    }
  };

  // Eliminar un input específico
  // Eliminar un input específico
  const handleRemoveInput = (index) => {
    // Filtrar inputs y sus estados
    const newInputs = inputs.filter((_, i) => i !== index);
    const newStatus = inputStatus.filter((_, i) => i !== index);

    // Actualizar estados
    setInputs(newInputs);
    setInputStatus(newStatus);
  };

  const handleInputChange = (index, value) => {
    const trimmedValue = value.trim().toLowerCase(); // Convertir a minúsculas para la comparación

    // Validar que el DOI no se repita (sin importar mayúsculas/minúsculas)
    const isDuplicate = inputs.some(
      (input, i) => i !== index && input.trim().toLowerCase() === trimmedValue
    );

    if (isDuplicate) {
      Swal.fire(
        "DOI Duplicado",
        "El DOI ingresado ya está en la lista. Por favor, ingresa un valor único.",
        "warning"
      );
      return;
    }

    const newInputs = [...inputs];
    newInputs[index] = value.trim(); // Mantener el formato original del valor ingresado
    setInputs(newInputs);

    const newStatus = [...inputStatus];
    newStatus[index] = value.trim() === "" ? "default" : newStatus[index];
    setInputStatus(newStatus);
  };

  // Consultar registro
  const handleSearch = async (value, index) => {
    Swal.fire({
      title: "Enviando consulta",
      html: "Espere por favor",
      didOpen: () => {
        Swal.showLoading();
      },
    });

    setHasQueried(true);
    setCurrentDOI(value); // Establece el DOI actual antes de la búsqueda

    try {
      // Primera consulta: API de CrossRef
      const response = await axios.get(
        `https://api.crossref.org/works/${encodeURIComponent(value)}`
      );
      const data = response.data.message;

      // Si se obtienen datos, actualizar resultados
      setResults({
        author:
          data.author?.map((a) => `${a.given} ${a.family}`).join(", ") || "N/A",
        title: data.title?.[0] || "N/A",
        volume: data.volume || "N/A",
        containerTitle: data["container-title"]?.[0] || "N/A",
      });

      // Marcar el input como exitoso
      const newStatus = [...inputStatus];
      newStatus[index] = "success";
      setInputStatus(newStatus);

      Swal.close();
    } catch (error) {
      try {
        // Consulta alternativa: API de DOI
        const url = `https://doi.org/${value}`;
        const response = await axios.get(url, {
          headers: {
            Accept: "application/x-bibtex", // Solicita la respuesta en formato BibTeX
          },
        });

        const bibtex = response.data;

        // Convierte el BibTeX a JSON usando la librería parseBibFile
        const parsedJson = parseBibFile(bibtex);
        const article = parsedJson.content.find(
          (item) => item.type === "article"
        );

        if (article) {
          const author = article?.fields?.author?.data?.join("") || "N/A";
          const title = article?.fields?.title?.data?.join("") || "N/A";
          const journal = article?.fields?.journal?.data?.join("") || "N/A";
          const volume = article?.fields?.volume?.data?.join("") || "N/A";

          setResults({
            author,
            title,
            volume,
            containerTitle: journal,
          });

          // Marcar el input como exitoso
          const newStatus = [...inputStatus];
          newStatus[index] = "success";
          setInputStatus(newStatus);
          Swal.close();
        } else {
          throw new Error("No se encontró el artículo en la API de DOI.");
        }
      } catch (doiError) {
        // Mostrar error al usuario
        Swal.fire(
          "Error",
          "No se pudo obtener la información del DOI",
          "error"
        );

        const newStatus = [...inputStatus];
        newStatus[index] = "error";
        setInputStatus(newStatus);
        setResults(null);
      }
    }
  };

  const sendAndValidateDOIS = async () => {
    // Validar que los campos obligatorios no estén vacíos
    const { rut } = contactData;

    const captchaValueForm1 = recaptchaForm1.current.getValue();

    // Validar que se haya seleccionado el reCAPTCHA
    if (!captchaValueForm1) {
      Swal.fire({
        icon: "error",
        title: "Captcha no validado",
        text: "Por favor, complete el reCAPTCHA antes de continuar.",
      });
      return; // Detener la ejecución
    }

    // Validar que el RUT sea válido
    if (!ChileanRutify.validRut(rut)) {
      Swal.fire({
        icon: "error",
        title: "RUT inválido",
        text: "El RUT ingresado no es válido. Por favor, ingrese nuevamente.",
      });
      return; // Detener la ejecución
    }

    // Inicializar Swal con loading
    const swalInstance = Swal.fire({
      title: "Procesando DOIs",
      html: "Espere mientras validamos los DOIs ingresados...",
      didOpen: () => {
        Swal.showLoading();
      },
      showConfirmButton: false, // No mostrar el botón de confirmación
      showCloseButton: false, // No mostrar el botón de cerrar
    });

    const resultsArray = [];
    const errorsArray = [];

    // Procesar cada DOI
    for (const [index, doi] of inputs.entries()) {
      if (!doi.trim()) {
        continue; // Ignorar campos vacíos
      }

      try {
        const response = await axios.get(
          `https://api.crossref.org/works/${encodeURIComponent(doi)}`
        );
        const data = response.data.message;

        resultsArray.push({
          doi,
          author:
            data.author?.map((a) => `${a.given} ${a.family}`).join(", ") ||
            "N/A",
          title: data.title?.[0] || "N/A",
          volume: data.volume || "N/A",
          containerTitle: data["container-title"]?.[0] || "N/A",
        });

        // Actualizar estado de input a success
        const newStatus = [...inputStatus];
        newStatus[index] = "success";
        setInputStatus(newStatus);
      } catch (error) {
        try {
          const url = `https://doi.org/${doi}`;
          const response = await axios.get(url, {
            headers: { Accept: "application/x-bibtex" },
          });

          const bibtex = response.data;
          const parsedJson = parseBibFile(bibtex);
          const article = parsedJson.content.find(
            (item) => item.type === "article"
          );

          if (article) {
            resultsArray.push({
              doi,
              author: article.fields.author?.data?.join("") || "N/A",
              title: article.fields.title?.data?.join("") || "N/A",
              volume: article.fields.volume?.data?.join("") || "N/A",
              containerTitle: article.fields.journal?.data?.join("") || "N/A",
            });

            const newStatus = [...inputStatus];
            newStatus[index] = "success";
            setInputStatus(newStatus);
          } else {
            throw new Error("No se encontró el artículo en la API de DOI.");
          }
        } catch (doiError) {
          errorsArray.push(doi);

          const newStatus = [...inputStatus];
          newStatus[index] = "error";
          setInputStatus(newStatus);
        }
      }
    }

    swalInstance.close();

    // Si no hay resultados, cerrar el loadifng y mostrar el error
    if (resultsArray.length === 0) {
      swalInstance.close(); // Cerrar el Swal cargando
      Swal.fire({
        title: "Cargando...",
        didOpen: () => {
          // Simular una operación asíncrona con setTimeout
          setTimeout(() => {
            // Parar el loading y actualizar el modal
            Swal.fire({
              icon: "warning",
              title: "Campos requeridos!",
              text: "Por favor, ingrese al menos un DOI válido.",
            });
          }, 0); // Cambia este valor para simular diferentes tiempos de carga
        },
      });
      return;
    }

    // Crear el JSON con `contactData` y los DOIs validados
    const finalData = {
      contactData,
      dois: resultsArray,
    };

    const flattenedData = {
      ...finalData.contactData, // Se extraen los datos de contactData
      dois: finalData.dois, // Se mantiene la clave dois intacta
    };

    const jsonFlattenedData = JSON.stringify(flattenedData, null, 2); // Para que sea legible en JSON

    // Renderizar resultados en pestañas con Swal.fire
    const tabsHTML = resultsArray
      .map(
        (result, idx) => `
        <div id="tab-${idx}" class="swal2-tab swal2-articles" style="display: ${
          idx === 0 ? "block" : "none"
        }">
          <br>
          <p><strong>DOI:</strong> ${result.doi}</p>
          <p><strong>Autor(es):</strong> ${result.author}</p>
          <p><strong>Título:</strong> ${result.title}</p>
          <p><strong>Revista:</strong> ${result.containerTitle}</p>
          <p><strong>Volumen:</strong> ${result.volume}</p>
        </div>
      `
      )
      .join("");

    const tabButtonsHTML = resultsArray
      .map(
        (_, idx) => `
          <button 
            id="tab-btn-${idx}" 
            class="swal2-tab-btn" 
            style="margin: 0 5px; padding: 5px 10px; border: 1px solid #ccc; border-radius: 5px; background-color: ${
              idx === 0 ? "#D12421" : "#f9f9f9"
            }; color: ${idx === 0 ? "#fff" : "#000"}; cursor: pointer;"
            onclick="
              document.querySelectorAll('.swal2-tab').forEach((tab, i) => tab.style.display = i === ${idx} ? 'block' : 'none');
              document.querySelectorAll('.swal2-tab-btn').forEach(btn => {
                btn.style.backgroundColor = '#f9f9f9';
                btn.style.color = '#000';
              });
              this.style.backgroundColor = '#D12421';
              this.style.color = '#fff';
            "
          >
            DOI ${idx + 1}
          </button>`
      )
      .join("");

    const loginResponse = await fetch(
      "https://sibucsc.cl/sibucsc/roundtable/token/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username: "gallahad",
          password: "f0rms-lancel0t",
        }),
      }
    );

    if (!loginResponse.ok) {
      throw new Error("Login failed");
    }

    const data = await loginResponse.json();

    Swal.fire({
      title: "Desear continuar?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí, enviar!",
      customClass: {
        htmlContainer: "swal2-tabs-container",
      },
      html: `
          <span style="text-align: center;">Información de DOIs encontrados</span> <br><br>
          <div style="text-align: left;">
            <div style="margin-bottom: 10px;">${tabButtonsHTML}</div>
            ${tabsHTML}
          </div>
        `,
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Enviando consulta",
          html: "Espere por favor",
          didOpen: () => {
            Swal.showLoading();
          },
        });

        // Simular el envío con Axios
        axios
          .post(
            "https://sibucsc.cl/sibucsc/solarticulo-doi/index/",
            jsonFlattenedData,
            {
              headers: {
                "Content-Type": "application/json", // Asegúrate de enviar el encabezado correcto
                Authorization: "Bearer " + data.access,
              },
            }
          )
          .then(() => {
            Swal.fire(
              "Solicitud enviada con éxito",
              "presione OK para terminar",
              "success"
            ).then(() => {
              window.location.reload();
            });
          })
          .catch(() => {
            Swal.fire({
              icon: "error",
              title: "Error al Enviar",
              text: "Ocurrió un problema al enviar tu solicitud.",
            });
          });
      }
    });
  };

  const handleSubmitSecondForm = async () => {
    const formData = {
      ...contactData,
      titulo: contactData.titulo || "",
      revista: contactData.revista || "",
      numero: contactData.numero || "",
      autor: contactData.autor || "",
      volumen: contactData.volumen || "",
      paginas: contactData.paginas || "",
    };

    const captchaValueForm2 = recaptchaForm2.current.getValue();

    // Validar que se haya seleccionado el reCAPTCHA
    if (!captchaValueForm2) {
      Swal.fire({
        icon: "error",
        title: "Captcha no validado",
        text: "Por favor, complete el reCAPTCHA antes de continuar.",
      });
      return; // Detener la ejecución
    }

    // Validar que el RUT sea válido
    if (!ChileanRutify.validRut(formData.rut)) {
      Swal.fire({
        icon: "error",
        title: "RUT inválido",
        text: "El RUT ingresado no es válido. Por favor, ingrese nuevamente.",
      });
      return; // Detener la ejecución
    }

    // Cuadro de confirmación
    const result = await Swal.fire({
      title: "¿Enviar formulario?",
      text: "Verifique que toda la información es correcta antes de enviar.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d12421",
      cancelButtonColor: "#aaa",
      confirmButtonText: "Sí, enviar",
      cancelButtonText: "Cancelar",
    });

    if (result.isConfirmed) {
      Swal.fire({
        title: "Enviando consulta",
        html: "Espere por favor",
        didOpen: () => {
          Swal.showLoading();
        },
      });
      try {
        const loginResponse = await fetch(
          "https://sibucsc.cl/sibucsc/roundtable/token/",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              username: "gallahad",
              password: "f0rms-lancel0t",
            }),
          }
        );

        if (!loginResponse.ok) {
          throw new Error("Login failed");
        }

        const data = await loginResponse.json();

        // Configuración del token en los encabezados
        const config = {
          headers: {
            Authorization: "Bearer " + data.access, // Reemplaza TU_TOKEN_AQUI con tu token dinámico o estático
          },
        };

        const response = await axios.post(
          "https://sibucsc.cl/sibucsc/solarticulo/index/",
          formData,
          config // Añade la configuración de encabezados
        ); // Cambia la URL según tu API
        Swal.fire(
          "Solicitud enviada con éxito",
          "presione OK para terminar",
          "success"
        ).then(() => {
          window.location.reload();
        });
        setContactData({
          rut: "",
          email: "",
          tipo_usuario: "",
          comentarios: "",
          titulo: "",
          revista: "",
          numero: "",
          autor: "",
          ejemplar: "",
        });
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Error al enviar",
          text: "Hubo un problema al enviar su formulario. Inténtelo nuevamente.",
        });
      }
    }
  };

  const renderContactFields = (contactData, setContactData) => {
    const handleChange = (e) => {
      const { name, value } = e.target;
      setContactData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    };

    return (
      <Row>
        <h5 className="text-[#d12421] font-bold">
          Formulario de solicitud
          <hr></hr>
        </h5>
        {/* Columna izquierda: Rut y Email */}
        <Col md={6}>
          <Form.Group className="mb-3">
            <Form.Label>Rut</Form.Label>
            <Form.Control
              type="text"
              name="rut"
              value={contactData.rut}
              onChange={handleChange}
              maxLength={9}
              placeholder="RUT sin puntos ni guión, Ej: 12345678K"
              required
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              name="email"
              value={contactData.email}
              onChange={handleChange}
              placeholder="Ingrese su correo electrónico"
              required
            />
          </Form.Group>
        </Col>

        {/* Columna derecha: UserType y Comments */}
        <Col md={6}>
          <Form.Group className="mb-3">
            <Form.Label>Tipo de Usuario</Form.Label>
            <Form.Control
              as="select"
              name="tipo_usuario"
              value={contactData.tipo_usuario}
              onChange={handleChange}
              required
            >
              <option value="">Seleccione tipo de usuario</option>
              <option value="Pregrado">Pregrado</option>
              <option value="Postgrado">Postgrado</option>
              <option value="Academico">Académico</option>
              <option value="Investigador">Investigador</option>
            </Form.Control>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Comentarios</Form.Label>
            <Form.Control
              as="textarea"
              name="comentarios"
              value={contactData.comentarios}
              onChange={handleChange}
              placeholder="Agregue información adicional (máx 250 carácteres)"
            />
          </Form.Group>
        </Col>
      </Row>
    );
  };

  // Mostrar el ReCAPTCHA al iniciar componente
  useEffect(() => {
    setShowRecaptcha(true);
  }, []);

  return (
    <>
      <Container fluid className="web-container pt-3" id="bannerSIBUCSC">
        <Row className="col d-flex justify-content-center">
          <Col xl={4}>
            <div className="col d-flex justify-content-center">
              <img src={Banner} id="imgBanner"></img>
            </div>
          </Col>
          <Col xl={6} className="mt-md-5">
            <div>
              <div className="m-3">
                <h3 className="main-heading text-white">
                  Solicitud de Artículos
                </h3>
                <h6 className="text-justify text-[#C9CCD2]">
                  El Sistema de Bibliotecas pone a disposición el servicio de
                  suministro de revistas impresas y electrónicas con artículo a
                  texto completo. Si no lograste descargar el artículo a Texto
                  completo, completa el formulario y te contactaremos. Este
                  servicio está abierto a todos los investigadores, docentes,
                  estudiantes de pregrado y postgrado de la Universidad Católica
                  de Santísima Concepción.
                </h6>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <Container className="mt-5">
        <Tab.Container
          id="right-tabs-doi"
          activeKey={activeTab}
          onSelect={handleTabSelect}
        >
          <Row>
            <Col sm={3}>
              <Nav variant="pills" className="flex-column">
                <p className="font-bold">Seleccione formulario</p>
                <Nav.Item>
                  <Nav.Link
                    eventKey="first"
                    className={
                      activeTab === "first" ? "tab-active-f" : "tab-default-f"
                    }
                  >
                    {/* Ingreso DOI */}
                    <Form.Check
                      type="radio"
                      id="radio-doi"
                      name="tabSelect"
                      label="Formulario para solicitud de artículos con DOI"
                      checked={activeTab === "first"}
                      onChange={() => handleTabSelect("first")}
                    />
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey="second"
                    className={
                      activeTab === "first" ? "tab-active-d" : "tab-default-d"
                    }
                  >
                    <Form.Check
                      type="radio"
                      id="radio-no-doi"
                      name="tabSelect"
                      label="Formulario para solicitud de artículos sin DOI"
                      checked={activeTab === "second"}
                      onChange={() => handleTabSelect("second")}
                    />
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              <div className="border p-3 mt-3">
                <p
                  className="mt-3 text-justify">
                  {activeTab === "first"
                    ? "En este formulario puede solicitar los artículos solo suministrando el DOI en el campo asignado para esto, de esta manera el sistema arrojará los datos relativos al artículo sin la necesidad de llenar los demás campos, que serán aportados por las bases de datos que lo contengan."
                    : "En este formulario puede solicitar artículos que no cuenten con DOI (publicaciones anteriores a 2015 u otros casos excepcionales). "}
                </p>

                <p className="mt-3 text-justify">
                  {activeTab === "first"
                    ? "Puede hacer una búsqueda hasta de cinco (5) artículos a través del DOI simultáneamente."
                    : "Para que la búsqueda sea precisa, es necesario proporcionar la mayor cantidad de información posible sobre el artículo, como: título, autor(es), año, nombre de la revista, volumen y número"}
                </p>

                <p>
                  {activeTab === "first"
                    ? `Al ingresar el DOI no es necesario añadir "DOI:"`
                    : ""}
                </p>

                <p className="mt-3 text-justify">
                  {activeTab === "first"
                    ? ""
                    : "Es importante tener en cuenta que solo se puede realizar una solicitud por artículo. Si necesita varios artículos, deberá completar un formulario individual para cada solicitud."}
                </p>
              </div>
            </Col>
            <Col sm={9}>
              <Tab.Content>
                <Tab.Pane eventKey="first">
                  <Form
                    className="border p-3"
                    action=""
                    method="post"
                    onSubmit={(e) => {
                      e.preventDefault(); // Prevenir envío nativo del formulario
                      sendAndValidateDOIS();
                    }}
                  >
                    <Row id="datosContacto" className="m-3">
                      {renderContactFields(contactData, setContactData)}
                    </Row>
                    <Row>
                      <Col md={6}>
                        <span className="m-3">
                          <strong>
                            Al ingresar DOI, no es necesario añadir el "https"
                          </strong>
                        </span>
                        {/* columna 1 */}
                        {inputs.map((value, index) => (
                          <div
                            key={index}
                            className="d-flex align-items-center m-3 divHR"
                          >
                            <Form.Control
                              type="text"
                              style={{ maxWidth: "240px" }}
                              className="ms-3 me-1"
                              required
                              value={value}
                              onChange={(e) =>
                                handleInputChange(index, e.target.value)
                              }
                              placeholder={`DOI ${index + 1}`}
                              isInvalid={
                                inputStatus[index] === "success"
                                  ? false
                                  : inputStatus[index] === "error"
                                  ? true
                                  : ""
                              }
                              isValid={
                                inputStatus[index] === "success"
                                  ? true
                                  : inputStatus[index] === "error"
                                  ? false
                                  : ""
                              }
                              disabled={inputStatus[index] === "success"}
                            />
                            {/* Botón de buscar */}
                            <Button
                              variant="outline-primary"
                              className="me-2 btnsearchdoi d-inline d-flex align-items-center"
                              onClick={() => handleSearch(value, index)}
                              disabled={!value.trim()}
                            >
                              <span className="mr-1">verificar</span>
                              <FaSearch />
                            </Button>
                            {index > 0 && (
                              <Button
                                variant="outline-danger"
                                onClick={() => handleRemoveInput(index)}
                              >
                                <FaTrash />
                              </Button>
                            )}
                          </div>
                        ))}

                        {inputs.length < 5 && (
                          <Button
                            variant="outline-danger"
                            className="ms-4 mt-4 btnAnnounc"
                            onClick={handleAddInput}
                          >
                            Agregar DOI
                          </Button>
                        )}

                        {/* Mostrar el ReCAPTCHA cuando showRecaptcha es true */}
                        {showRecaptcha && (
                          <div className="col d-flex justify-content-center mt-5">
                            <ReCAPTCHA
                              sitekey={process.env.REACT_APP_SITE_KEY}
                              ref={recaptchaForm1}
                            />
                          </div>
                        )}

                        <div className="col d-flex justify-content-center">
                          <Button
                            variant="outline-danger"
                            className="ms-4 mt-4 btnAnnounc"
                            type="submit"
                          >
                            Enviar formulario
                          </Button>
                        </div>
                      </Col>

                      <Col md={6} className="borderLeftDoi">
                        {/* Párrafo fijo */}
                        <div>
                          <p className="m-3">
                            <strong>Información del DOI consultado</strong>
                            <br></br>
                            <span>
                              <strong>{currentDOI}</strong>
                            </span>
                          </p>
                          <p
                            className="m-3"
                            style={{
                              textDecoration: "underline",
                              fontWeight: "bold",
                            }}
                            hidden={hasQueried ? true : false}
                          >
                            Haga click en ícono de verificar para ver info del
                            DOI
                          </p>
                          {/* Mostrar resultados o mensaje de error */}
                          {hasQueried ? (
                            results ? (
                              <div className="m-3">
                                <p>
                                  <strong>Autor(es):</strong> {results.author}
                                </p>
                                <p>
                                  <strong>Título:</strong> {results.title}
                                </p>
                                <p>
                                  <strong>Revista:</strong>{" "}
                                  {results.containerTitle}
                                </p>
                                <p>
                                  <strong>Volumen:</strong> {results.volume}
                                </p>
                              </div>
                            ) : (
                              <p className="mt-3 ms-3">
                                No existen datos asociados al DOI
                              </p>
                            )
                          ) : null}
                        </div>
                        <br></br>
                      </Col>
                    </Row>
                  </Form>
                </Tab.Pane>
                <Tab.Pane eventKey="second">
                  <Form
                    className="border p-3"
                    action=""
                    method="post"
                    onSubmit={(e) => {
                      e.preventDefault(); // Prevenir envío nativo del formulario
                      handleSubmitSecondForm();
                    }}
                  >
                    <Row id="datosContacto" className="m-3">
                      {renderContactFields(contactData, setContactData)}
                    </Row>
                    <Row className="m-3">
                      <Col md={6}>
                        {/* Campos adicionales exclusivos para la pestaña 'second' */}
                        <Form.Group className="mb-3">
                          <Form.Label>Título del Artículo</Form.Label>
                          <Form.Control
                            type="text"
                            name="titulo"
                            value={contactData.titulo || ""}
                            onChange={(e) =>
                              setContactData({
                                ...contactData,
                                titulo: e.target.value,
                              })
                            }
                            placeholder="Ingrese el título del artículo"
                            required
                          />
                        </Form.Group>

                        <Form.Group className="mb-3">
                          <Form.Label>Título de la Revista</Form.Label>
                          <Form.Control
                            type="text"
                            name="revista"
                            value={contactData.revista || ""}
                            onChange={(e) =>
                              setContactData({
                                ...contactData,
                                revista: e.target.value,
                              })
                            }
                            placeholder="Ingrese el título de la revista"
                            required
                          />
                        </Form.Group>

                        <Form.Group className="mb-3">
                          <Form.Label>Número</Form.Label>
                          <Form.Control
                            type="text"
                            name="numero"
                            value={contactData.numero || ""}
                            onChange={(e) =>
                              setContactData({
                                ...contactData,
                                numero: e.target.value,
                              })
                            }
                            placeholder="Ingrese el número de la revista"
                            required
                          />
                        </Form.Group>
                      </Col>

                      <Col md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>Autor/Autores</Form.Label>
                          <Form.Control
                            type="text"
                            name="autor"
                            value={contactData.autor || ""}
                            onChange={(e) =>
                              setContactData({
                                ...contactData,
                                autor: e.target.value,
                              })
                            }
                            placeholder="Ingrese el nombre del autor"
                            required
                          />
                        </Form.Group>

                        <Form.Group className="mb-3">
                          <Form.Label>Volúmen</Form.Label>
                          <Form.Control
                            type="text"
                            name="volumen"
                            value={contactData.volumen || ""}
                            onChange={(e) =>
                              setContactData({
                                ...contactData,
                                volumen: e.target.value,
                              })
                            }
                            placeholder="Ingrese el volúmen de la revista"
                            required
                          />
                        </Form.Group>

                        <Form.Group className="mb-3">
                          <Form.Label>Páginas</Form.Label>
                          <Form.Control
                            type="text"
                            name="paginas"
                            value={contactData.paginas || ""}
                            onChange={(e) =>
                              setContactData({
                                ...contactData,
                                paginas: e.target.value,
                              })
                            }
                            placeholder="Ingrese páginas"
                            required
                          />
                        </Form.Group>
                      </Col>
                    </Row>

                    <div className="col d-flex justify-content-center mt-5">
                      <ReCAPTCHA
                        sitekey={process.env.REACT_APP_SITE_KEY}
                        ref={recaptchaForm2}
                      />
                    </div>

                    <div className="col d-flex justify-content-center">
                      <Button
                        variant="outline-danger"
                        id="btnForm2"
                        type="submit"
                        className="m-3 btnAnnounc"
                      >
                        Enviar formulario
                      </Button>
                    </div>
                  </Form>
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
        <br></br>
      </Container>
    </>
  );
};

export default RightAlignedNavs;
